<template>
  <div class="big">
    <el-dialog title="选择历史图片"
               :visible.sync="dialog"
               width="1020px"
               style="text-align:left"
               :close-on-click-modal="false"
               :before-close="close">
      <div class="diabox">
        <div style="margin-bottom:16px">
          <el-radio-group v-model="year"
                          @change="getList">
            <el-radio-button :label="''">不限年份</el-radio-button>
            <el-radio-button :label="2022">2022</el-radio-button>
            <el-radio-button :label="2021">2021</el-radio-button>
            <el-radio-button :label="2020">2020</el-radio-button>
            <el-radio-button :label="2019">2019</el-radio-button>
            <el-radio-button :label="2018">2018</el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <el-radio-group v-model="month"
                          @change="getList">
            <el-radio-button :label="''">不限月份</el-radio-button>
            <el-radio-button :label="1"></el-radio-button>
            <el-radio-button :label="2"></el-radio-button>
            <el-radio-button :label="3"></el-radio-button>
            <el-radio-button :label="4"></el-radio-button>
            <el-radio-button :label="5"></el-radio-button>
            <el-radio-button :label="6"></el-radio-button>
            <el-radio-button :label="7"></el-radio-button>
            <el-radio-button :label="8"></el-radio-button>
            <el-radio-button :label="9"></el-radio-button>
            <el-radio-button :label="10"></el-radio-button>
            <el-radio-button :label="11"></el-radio-button>
            <el-radio-button :label="12"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="imgbox">
          <div v-for="item in imgList"
               :key="item.pic_id"
               class="imgitem">
            <img :src="item.pic_url_str"
                 class="img"
                 @click="checkImg(item)" />
            <i class="el-icon-delete"
               @click.stop="delImg(item)"></i>
            <img :src="check"
                 class="checkimg"
                 v-if="item.check"
                 @click="checkImg(item)" />
          </div>
        </div>
        <div class="flex between"
             style="margin-top:15px">
          <el-pagination layout="prev, pager, next"
                         :total="total"
                         :page-size="21"
                         :current-page="page"
                         @current-change="handleCurrentChange"></el-pagination>
          <div>
            <el-button plain
                       style="width:90px"
                       @click="close">取消</el-button>
            <el-button type="primary"
                       style="width:90px"
                       @click="submit">确认</el-button>
          </div>

        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
      year: "",
      month: "",
      check: require("../../assets/checkimg.png"),
      page: 1,
      total: 0,
      imgList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getList()
    },
    getList() {
      let data = {
        year: this.year,
        month: this.month,
        page: this.page,
        pageNumber: 21,
      };
      this.axios.post("/store/Shoppicture/getPicList", data).then((res) => {
        this.total = res.data.total;
        this.imgList = res.data.list.map((item) => {
          item.check = false;
          return item;
        });
      });
    },
    //选中图片
    checkImg(item) {
      let arr =
        this.imgList.filter((ele) => {
          return ele.check;
        }) || [];
      if (arr.length == 5 && !item.check) {
        this.$message.error("最多选中5张图片");
        return;
      }
      item.check = !item.check;
    },
    delImg(item) {
      if (item.check) {
        this.$message.error("请先取消选中再删除");
        return;
      }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = { pic_id: item.pic_id };
          return this.axios.post("/store/Shoppicture/delPic", data);
        })
        .then(() => {
          this.$message.success("删除成功");
          this.getList();
        })
        .catch(() => { });
    },
    //提交
    submit() {
      let imgData =
        this.imgList.filter((ele) => {
          return ele.check;
        }) || [];
      this.$emit("chooseImg", imgData);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.diabox {
  box-sizing: border-box;
}
.imgbox {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .img {
    width: auto;
    height: 110px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  .imgitem {
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      i {
        display: block;
      }
    }
    i {
      position: absolute;
      font-size: 20px;
      top: 0px;
      right: 10px;
      z-index: 100;
      color: #d44747;
      display: none;
    }
    .checkimg {
      position: absolute;
      width: 60px;
      top: 50%;
      right: 50%;
      margin-top: -20px;
      margin-right: -30px;
      z-index: 100;
    }
  }
}
</style>
